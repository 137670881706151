import React, { useState } from 'react'
import cx from 'classnames'
import Title from '../Title/Title'
import Plus from '../Plus'
import { tr } from '../../utils/translate'
import AccordionContent from '../AccordionContent'
import IconArrow from '../IconArrow'
import { questions } from './questions'
import styles from './index.module.scss'

export default () => {
  const [opened, setOpened] = useState(questions[0].questionTag)
  const onQuestionClick = questionTag =>
    setOpened(questionTag === opened ? null : questionTag)

  return (
    <section className={styles.wrapper} id="faq">
      <Title className={styles.title}>
        <IconArrow />
        {tr('faq.title', 'FAQ')}
      </Title>
      <ul className={styles.questions}>
        {questions.map(({ question, answer, questionTag }) => (
          <li
            className={cx(
              styles.question,
              opened === questionTag && styles.opened
            )}
            onClick={() => onQuestionClick(questionTag)}
            key={question}
            id={`faq-${questionTag}`}
          >
            <div className={styles.question__top}>
              <h3 className={styles.question__text}>
                {tr(`faq.question.${questionTag}`, question)}
              </h3>
              <Plus
                className={styles.question__arrow}
                opened={opened === questionTag}
              />
            </div>
            <AccordionContent show={opened === questionTag}>
              <div className={styles.question__answer}>
                {tr(`faq.answer.${questionTag}`, answer)}
              </div>
            </AccordionContent>
          </li>
        ))}
      </ul>
    </section>
  )
}
