import React from 'react'
import styles from './SharedFeatures.module.scss'
import CheckmarkCircle from './CheckmarkCircle'

export default () => {
  const sharedFeatures = [
    'Sanbase access',
    'API calls',
    'Queries credits',
    'Support',
  ]

  return (
    <div className={styles.allPlans}>
      All plans include:
      <div className={styles.features}>
        {sharedFeatures.map(feature => (
          <div className={styles.feature}>
            <CheckmarkCircle />
            {feature}
          </div>
        ))}
      </div>
    </div>
  )
}
