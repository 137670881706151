import React from 'react'
import Lottie from 'lottie-react'
import styles from './index.module.scss'
import SectionHeader from '../SectionHeader'
import IconArrow from '../IconArrow'
import animationData from './cubes.json'

const points = [
  {
    title: 'Leader in sentiment data',
    subtitle: 'In sentiment crypto metrics, leading the market since 2014',
  },
  {
    title: 'Pioneers in Dev data',
    subtitle: 'Since 2017, pioneering analytics of the crypto development data',
  },
  {
    title: 'Historical data since 2009',
    subtitle:
      "We've been gathering historical data so you can look back and backtest your strategy",
  },
  {
    title: '12 blockchains',
    subtitle:
      'Blockchains and 2500+ assets, we offer 500+ on-chain metrics for comprehensive analysis',
  },
]

const numbers = [
  {
    title: '75+ Million',
    subtitle: 'Labelled ETH Addresses',
  },
  {
    title: '65+ Million',
    subtitle: 'Labelled BTC Addresses',
  },
  {
    title: '3+ Million',
    subtitle: 'Parsed Messages Monthly',
  },
  {
    title: '2500+',
    subtitle: 'Crypto Assets',
  },
  {
    title: '500+',
    subtitle: 'Metrics',
  },
]

export default () => (
  <section className={styles.wrapper}>
    <div className={styles.main}>
      <div className={styles.content}>
        <SectionHeader
          classes={{ header: styles.header }}
          title="Your Reliable Source of Data"
          subtitle="Santiment API is your partner in navigating the complex world of information with confidence"
        />

        <div className={styles.points}>
          {points.map(({ title, subtitle }) => (
            <div className={styles.point}>
              <IconArrow className={styles.point__icon} />
              <div className={styles.point__content}>
                <h4 className={styles.point__title}>{title}</h4>
                <p className={styles.point__subtitle}>{subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.animation}>
        <Lottie animationData={animationData} />
      </div>
    </div>

    <div className={styles.numbers}>
      {numbers.map(({ title, subtitle }) => (
        <div className={styles.number}>
          <h4 className={styles.number__title}>{title}</h4>
          <p className={styles.number__subtitle}>{subtitle}</p>
        </div>
      ))}
    </div>
  </section>
)
