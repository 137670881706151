import React, { useState } from 'react'
import cx from 'classnames'
import { tr } from '../../utils/translate'
import { trackInteraction } from '../../utils/events'
import IconArrow from '../IconArrow'
import SectionHeader from '../SectionHeader'
import { examples } from './data'
import styles from './index.module.scss'

export default () => {
  const [tab, setTab] = useState(examples[0])

  return (
    <div id="features" className={styles.container}>
      <section className={styles.wrapper}>
        <SectionHeader
          title={tr('bullets.title', 'See WHAT IS POSSIBLE with our API')}
          subtitle={tr(
            'bullets.subtitle',
            'Test these queries yourself and discover how our API can help you backtest strategies or create trading models'
          )}
        />

        <div className={styles.examples}>
          <div className={styles.code}>
            <div className={styles.code__header}>
              <h4 className={styles.code__title}>{tab.title}</h4>
              <a
                href={tab.link}
                data-source="bullets"
                data-type="try_live"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.code__live}
              >
                View Query Result
              </a>
            </div>

            <div className={styles.explorer}>
              <div className={styles.badge}>Query</div>
              <pre className={styles.query}>{tab.query}</pre>
            </div>
          </div>

          <div className={styles.tabs}>
            {examples.map(item => (
              <div
                key={item.title}
                className={cx(
                  styles.metricTab,
                  item.title === tab.title && styles.metricTab__active
                )}
                onClick={() => {
                  trackInteraction({
                    type: 'select_query_example',
                    query_name: item.title,
                  })
                  setTab(item)
                }}
              >
                <div className={styles.metricTab__icon}>
                  <IconArrow />
                </div>
                {item.title || item.title}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
