import React from 'react'

const Arrow = ({ className = '' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="26"
      viewBox="0 0 14 26"
      fill="none"
    >
      <path
        d="M0.875844 2.82862C0.397816 2.32133 0.374885 1.49728 0.812311 0.958775C1.28129 0.381418 2.10948 0.339714 2.6244 0.886166L13.1242 12.0288C13.3709 12.2906 13.5 12.6438 13.5 13C13.5 13.3562 13.3709 13.7094 13.1242 13.9712L2.62441 25.1138C2.10948 25.6603 1.28129 25.6186 0.812312 25.0412C0.374886 24.5027 0.397817 23.6787 0.875845 23.1714L10.4604 13L0.875844 2.82862Z"
        fill="#5275FF"
        stroke="#5275FF"
      />
    </svg>
  )
}

export default Arrow
