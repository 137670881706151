import React from 'react'
import Lottie from 'lottie-react'
import Button from '@santiment-network/ui/Button'
import SectionHeader from '../SectionHeader'
import animationData from './calendar.json'
import styles from './index.module.scss'

export default () => {
  return (
    <section className={styles.section}>
      <div className={styles.animation}>
        <Lottie animationData={animationData} />
      </div>

      <SectionHeader
        classes={{ header: styles.header }}
        title="Meet our Experts and Discover the Capabilities of Santiment API"
        subtitle="Fill-in the form to book a demo with us, ask us anything"
      >
        <Button
          as="a"
          href="https://calendly.com/santiment-team/sanapi-walkthrough"
          target="_blank"
          variant="fill"
          accent="blue"
          className={styles.button}
        >
          Schedule my Demo
        </Button>
      </SectionHeader>
    </section>
  )
}
