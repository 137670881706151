import React from 'react'
import Title from '../Title/Title'
import { tr } from '../../utils/translate'
import PricingTable from './PricingTable.js'
import IconArrow from '../IconArrow.js'
import styles from './index.module.scss'
import SharedFeatures from './SharedFeatures.js'
import Icon from '@santiment-network/ui/Icon/index.js'

const Pricing = () => (
  <section id="pricing" className={styles.pricing}>
    <Title className={styles.title}>
      <IconArrow />
      {tr('pricing.title', 'Pick Your Plan')}
    </Title>
    <SharedFeatures />

    <a
      href="https://app.santiment.net/pricing"
      target="_blank"
      className={styles.breakdown}
    >
      Breakdown of the plans
      <Icon type="pointer-right" />
    </a>

    <PricingTable />
  </section>
)

export default Pricing
