import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import Button from '@santiment-network/ui/Button'
import { tr } from '../../utils/translate'
import { trackInteraction } from '../../utils/events'
import styles from './GetStarted.module.scss'

function onGetAccessClick() {
  window.gtag('event', 'login_action_call', {
    location: '"Ready to start?" section',
    text: 'Get access',
  })
}

function onDocumentationClick() {
  window.gtag('event', 'documentation_clicked', {
    location: '"Ready to start?" section',
  })
}

function onBookDemoClick() {
  window.Calendly.initPopupWidget({
    url: 'https://calendly.com/santiment-team/sanapi-walkthrough',
  })
  window.gtag('event', 'book_demo_clicked', {
    location: '"Ready to start?" section',
  })
  trackInteraction({ type: 'request_a_demo', source: 'cta' })
}

const GetStarted = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <h4 className={styles.title}>
        {tr('ready.title', 'Ready to get started? Sign up now!')}
      </h4>
      <div className={styles.buttons}>
        <Button
          as={Link}
          to="/account"
          data-source="cta"
          data-type="get_api_key"
          accent="blue"
          className={styles.btn}
          onClick={onGetAccessClick}
        >
          {tr('main.get_access', 'Get API Key')}
        </Button>
        <Button
          as="a"
          href="https://api.santiment.net/graphiql"
          data-source="cta"
          data-type="explore_api"
          target="_blank"
          rel="noopener noreferrer"
          variant="fill"
          accent="blue"
          className={styles.btn}
          onClick={onDocumentationClick}
        >
          {tr('main.documentation', 'Explore API')}
        </Button>
        <Button
          as="a"
          variant="fill"
          accent="blue"
          className={styles.btn}
          onClick={onBookDemoClick}
        >
          Request a demo
        </Button>
      </div>
    </div>
  </div>
)

export default GetStarted
