import React from 'react'
import cx from 'classnames'
import Title from '../Title/Title'
import Subtitle from '../Subtitle/Subtitle'
import styles from './index.module.scss'

export default ({ classes = {}, title, subtitle, children }) => (
  <header className={cx(styles.header, classes.header)}>
    <Title className={styles.title}>{title}</Title>
    <Subtitle className={styles.subtitle}>{subtitle}</Subtitle>

    {children && <div className={styles.content}>{children}</div>}
  </header>
)
