import React from 'react'
import cx from 'classnames'
import Bitfinex from '../../images/customers/bitfinex.png'
// import Hedgehog from '../../images/customers/hedgehog.png'
import Alluva from '../../images/customers/alluva.png'
import Arrano from '../../images/customers/arrano.png'
import styles from './index.module.scss'
import IconArrow from '../IconArrow'

const imgs = [
  {
    logo: Bitfinex,
    text: 'Bitfinex',
    style: styles.bitfinex,
  },
  // {
  //   logo: Hedgehog,
  //   text: 'Hedgehog',
  //   style: styles.hedgehog,
  // },
  {
    logo: Alluva,
    text: 'Alluva',
    style: styles.alluva,
  },
  {
    logo: Arrano,
    text: 'Arrano',
    style: styles.arrano,
  },
]

export default () => (
  <section>
    <h4 className={styles.title}>
      <IconArrow />
      Trusted by
    </h4>

    <ul className={styles.customers}>
      {imgs.map(({ logo, text, style }) => (
        <li className={styles.imgWrapper} key={text}>
          <img src={logo} className={cx(styles.img, style)} alt={text} />
        </li>
      ))}
    </ul>
  </section>
)
