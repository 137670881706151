import React, { useEffect } from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { emitter } from '@marvelapp/react-ab-test'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Customers from '../components/Customers'
import Bullets from '../components/Bullets'
import Pricing from '../components/Pricing'
import Numbers from '../components/Numbers'
import FAQ from '../components/FAQ'
import Twitter from '../components/Twitter'
import GetStarted from '../components/GetStarted/GetStarted'
import FirstBlock from '../components/FirstBlock'
import Demo from '../components/Demo'

// TODO: Remove variants rate after prod test
emitter.defineVariants(
  'SanApi Questionnaire',
  ['Questionnaire', 'Old'],
  [0, 100]
)

const IndexPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const questionnaireParam = params.get('exp')

  if (questionnaireParam === 'questionnaire')
    emitter.setActiveVariant('SanApi Questionnaire', 'Questionnaire')
  if (questionnaireParam === 'old')
    emitter.setActiveVariant('SanApi Questionnaire', 'Old')

  useEffect(() => {
    document.body.classList.toggle('night-mode', true)

    return () => document.body.classList.toggle('night-mode', false)
  }, [])

  return (
    <Layout>
      <SEO title="Cryptocurrency Data API - On-chain, Social, Dev & Pricing Data" />
      <FirstBlock />
      {/* <Customers /> */}
      <Bullets />
      <Demo />
      <Numbers />
      <Pricing />
      <FAQ />
      {/* <Twitter /> */}
      {/* <GetStarted /> */}
    </Layout>
  )
}

export default injectIntl(IndexPage)
