import React from "react"
import cx from "classnames"
import styles from "./index.module.scss"

export default ({ className, opened }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    className={cx(styles.arrow, opened && styles.opened, className)}
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none">
      <path d="M14 25.67a11.67 11.67 0 100-23.34 11.67 11.67 0 000 23.34z" />
      <path d="M14 9.33v9.34" className={styles.arrow_v} />
      <path d="M9.33 14h9.34" className={styles.arrow_h} />
    </g>
  </svg>
)
