export const examples = [
  {
    title: 'Coins with highest price increase',
    query: `query($fn:json) {
      allProjectsByFunction(function: $fn) {
        projects {
          slug name ticker
          price_usd_change_1d:aggregatedTimeseriesData(
              metric:"price_usd_change_1d"
              from:"utc_now-1d"
              to:"utc_now"
              aggregation:LAST
            )
        }
      }
  }`,
    link:
      'https://api.santiment.net/graphiql?query=query($fn:json)%7B%0A%20%20%20%20allProjectsByFunction(function:%20$fn)%20%7B%0A%20%20%20%20%20%20projects%20%7B%0A%20%20%20%20%20%20%20%20slug%20name%20ticker%0A%20%20%20%20%20%20%20%20price_usd_change_1d:aggregatedTimeseriesData(%0A%20%20%20%20%20%20%20%20%20%20%20%20metric:%22price_usd_change_1d%22%0A%20%20%20%20%20%20%20%20%20%20%20%20from:%22utc_now-1d%22%0A%20%20%20%20%20%20%20%20%20%20%20%20to:%22utc_now%22%0A%20%20%20%20%20%20%20%20%20%20%20%20aggregation:LAST%0A%20%20%20%20%20%20%20%20%20%20)%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%7D&variables=%7B%0A%09%09%22fn%22:%20%22%7B%5C%22name%5C%22:%5C%22selector%5C%22,%5C%22args%5C%22:%7B%5C%22filters%5C%22:%5B%7B%5C%22args%5C%22:%7B%5C%22metric%5C%22:%5C%22marketcap_usd%5C%22,%5C%22operator%5C%22:%5C%22greater_than%5C%22,%5C%22dynamicFrom%5C%22:%5C%221d%5C%22,%5C%22dynamicTo%5C%22:%5C%22now%5C%22,%5C%22aggregation%5C%22:%5C%22last%5C%22,%5C%22threshold%5C%22:10000000%7D,%5C%22name%5C%22:%5C%22metric%5C%22%7D%5D,%5C%22orderBy%5C%22:%7B%5C%22aggregation%5C%22:%5C%22last%5C%22,%5C%22dynamicFrom%5C%22:%5C%221d%5C%22,%5C%22direction%5C%22:%5C%22desc%5C%22,%5C%22dynamicTo%5C%22:%5C%22now%5C%22,%5C%22metric%5C%22:%5C%22price_usd_change_1d%5C%22%7D,%5C%22pagination%5C%22:%7B%5C%22page%5C%22:1,%5C%22pageSize%5C%22:10%7D%7D%7D%22%0A%09%7D',
  },
  {
    title: 'Coins with highest development activity',
    query: `query($fn:json){
      allProjectsByFunction(function: $fn) {
        projects {
          slug name ticker logoUrl
          dev_activity:aggregatedTimeseriesData(
              metric:"dev_activity_1d"
              from:"utc_now-7d"
              to:"utc_now"
              aggregation:AVG
            )

        }
      }
    }`,
    link:
      'https://api.santiment.net/graphiql?query=query($fn:json)%7B%0A%20%20%20%20allProjectsByFunction(function:%20$fn)%20%7B%0A%20%20%20%20%20%20projects%20%7B%0A%20%20%20%20%20%20%20%20slug%20name%20ticker%20logoUrl%0A%20%20%20%20%20%20%20%20dev_activity:aggregatedTimeseriesData(%0A%20%20%20%20%20%20%20%20%20%20%20%20metric:%22dev_activity_1d%22%0A%20%20%20%20%20%20%20%20%20%20%20%20from:%22utc_now-7d%22%0A%20%20%20%20%20%20%20%20%20%20%20%20to:%22utc_now%22%0A%20%20%20%20%20%20%20%20%20%20%20%20aggregation:AVG%0A%20%20%20%20%20%20%20%20%20%20)%0A%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D&variables=%7B%0A%09%09%22fn%22:%20%22%7B%5C%22name%5C%22:%5C%22selector%5C%22,%5C%22args%5C%22:%7B%5C%22filters%5C%22:%5B%5D,%5C%22orderBy%5C%22:%7B%5C%22aggregation%5C%22:%5C%22avg%5C%22,%5C%22dynamicFrom%5C%22:%5C%227d%5C%22,%5C%22direction%5C%22:%5C%22desc%5C%22,%5C%22dynamicTo%5C%22:%5C%22now%5C%22,%5C%22metric%5C%22:%5C%22dev_activity_1d%5C%22%7D,%5C%22pagination%5C%22:%7B%5C%22page%5C%22:1,%5C%22pageSize%5C%22:25%7D%7D%7D%22%0A%09%7D%0A',
  },
  {
    title: 'Show Total holdings of 100-1000 BTC addresses in June 2021',
    metric: 'Supply Distribution by balance',
    query: `{
  getMetric(metric: "holders_distribution_combined_balance_100_to_1k") {
    timeseriesData(
      slug: "bitcoin"
      from: "2021-06-01T00:00:00.000Z"
      to: "2021-07-01T00:00:00Z"
      interval: "1d"
    ) { datetime value }
 }
}`,
    link:
      'https://api.santiment.net/graphiql?query=%7B%0A%20%20getMetric(metric%3A%20%22holders_distribution_combined_balance_100_to_1k%22)%20%7B%0A%20%20%20%20timeseriesData(%0A%20%20%20%20%20%20slug%3A%20%22bitcoin%22%0A%20%20%20%20%20%20from%3A%20%222021-06-01T00%3A00%3A00.000Z%22%0A%20%20%20%20%20%20to%3A%20%222021-07-01T00%3A00%3A00Z%22%0A%20%20%20%20%20%20interval%3A%20%221d%22%0A%20%20%20%20)%20%7B%20datetime%20value%20%7D%0A%20%7D%0A%7D&variables=%7B%7D',
  },
  {
    title: "Compare the dev activity of Polkadot vs Ethereum in Feb 21'",
    metric: 'Development activity',
    query: `{
  eth: getMetric(metric:"dev_activity"){
    timeseriesData(
      slug: "ethereum"
      from: "2021-02-01T00:00:00Z"
      to: "2021-02-28T00:00:00Z"
      interval: "28d"
      aggregation: SUM
    ) { datetime value }
  }
  polka: getMetric(metric:"dev_activity"){
    timeseriesData(
      slug: "polkadot-new"
      from: "2021-02-01T00:00:00Z"
      to: "2021-02-28T00:00:00Z"
      interval: "28d"
      aggregation: SUM
    ) { datetime value }
  }
}`,
    link:
      'https://api.santiment.net/graphiql?query=%7B%0A%20%20eth%3A%20getMetric(metric%3A%22dev_activity%22)%7B%0A%20%20%20%20timeseriesData(%0A%20%20%20%20%20%20slug%3A%20%22ethereum%22%0A%20%20%20%20%20%20from%3A%20%222021-02-01T00%3A00%3A00Z%22%0A%20%20%20%20%20%20to%3A%20%222021-02-28T00%3A00%3A00Z%22%0A%20%20%20%20%20%20interval%3A%20%2228d%22%0A%20%20%20%20%20%20aggregation%3A%20SUM%0A%20%20%20%20)%20%7B%20datetime%20value%20%7D%0A%20%20%7D%0A%20%20polka%3A%20getMetric(metric%3A%22dev_activity%22)%7B%0A%20%20%20%20timeseriesData(%0A%20%20%20%20%20%20slug%3A%20%22polkadot-new%22%0A%20%20%20%20%20%20from%3A%20%222021-02-01T00%3A00%3A00Z%22%0A%20%20%20%20%20%20to%3A%20%222021-02-28T00%3A00%3A00Z%22%0A%20%20%20%20%20%20interval%3A%20%2228d%22%0A%20%20%20%20%20%20aggregation%3A%20SUM%0A%20%20%20%20)%20%7B%20datetime%20value%20%7D%0A%20%20%7D%0A%7D&variables=%7B%7D',
  },
]
