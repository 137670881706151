import React from 'react'
import Lottie from 'lottie-react'
import { Link } from 'gatsby-plugin-intl'
import Button from '@santiment-network/ui/Button'
import { tr } from '../../utils/translate'
import styles from './index.module.scss'
import animationData from './cubes.json'

function onGetAccessClick() {
  window.gtag('event', 'login_action_call', {
    location: 'Main section',
    text: 'Get access',
  })
}

function onDocumentationClick() {
  window.gtag('event', 'documentation_clicked')
}

const FirstBlock = () => (
  <section className={styles.wrapper}>
    <div className={styles.header}>
      <h1 className={styles.title}>
        {tr('main.title', 'the DATA FUEL your FINANCIAL MODELS need')}
      </h1>
      <h2 className={styles.desc}>
        {tr(
          'main.description',
          'Uncover insights about the crypto market from SOCIAL, DEV, and ON-CHAIN activity – ALL IN ONE PLACE'
        )}
      </h2>
      <div className={styles.actions}>
        <Button
          as={Link}
          to="/account"
          data-source="main"
          data-type="get_api_key"
          className={styles.access}
          variant="fill"
          accent="blue"
          onClick={onGetAccessClick}
        >
          {tr('main.get_access', 'Get a Free API Key')}
        </Button>
        <Button
          as="a"
          href="https://academy.santiment.net/for-developers/"
          data-source="main"
          data-type="documentaion"
          target="_blank"
          rel="noopener noreferrer"
          accent="blue"
          border
          className={styles.doc}
          onClick={onDocumentationClick}
        >
          {tr('main.documentation', 'Documentation')}
        </Button>
      </div>
    </div>

    <div className={styles.animation}>
      <Lottie animationData={animationData} />
    </div>
  </section>
)

export default FirstBlock
